import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import {
  of,
  throwError,
  zip,
} from 'rxjs';
import { Router } from '@angular/router';
import { catchError, filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectUrl } from '../store/router/router.selectors';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { globalFailure } from '../store/common.actions';
import * as _ from 'lodash';

type MergedSkinConfig = SkinBaseConfig['base'];


import {
  SkinConfig,
} from '@gridscale/ingrid/helper/services/skinning.service';
import { SkinBaseConfig } from '../../../config/config.base';
import { SkinSpecialConfig } from '../../../config/config.skin';

type LoadedState = '' | 'success' | 'failure';

export interface ConfigResponse {
  body: {
    env: string;
    vhost: string;
    logger: string;
    partner_uuid: string;
    saml: {
      samls: string[];
    };
    name: string;
    core_api: string;
    skin: string;
    magiclink: boolean;
    signup: boolean;
    s3_api: string;
    skin_detail: SkinConfig & {
      external_links?: {
        website_link: string;
        website_link_label: string;
        product_documentation: string;
        privacy: string;
        imprint: string;
        contact_form: string;
        release_notes: string;
      };
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class SkinConfigService {


  private mySkinConfig?: MergedSkinConfig;
  private loadedSkinConfig: LoadedState = '';


  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly http: HttpClient,
    private readonly location: Location,
    @Inject(DOCUMENT) private doc: Document
  ) { }



  get skinConfig() {
    return this.mySkinConfig;
  }



  /**
   * Load the skin dependent config via json
   */
  async loadSkinConfig() {
    return new Promise<void>((_resolve, _reject) => {
      // fetch the base config
      zip(
        this.http.get<SkinBaseConfig>(
          this.location.prepareExternalUrl('/config/config.base.json')
        ),
        this.http
          .get<SkinSpecialConfig>(
            this.location.prepareExternalUrl('/skin/config/config.json')
          )
          .pipe(
            catchError((e) => {
              // error is okay here as we do not neccassary have a skin config anymore
              return of({ skin: {} });
            })
          )
      )
        .pipe(
          catchError((err) => {
            this.store.dispatch(globalFailure({ error: err, method: 'GET' }));
            return throwError(err);
          })
        )
        .subscribe(
          ([baseConfig, specialConfig]) => {
            this.mySkinConfig = _.merge(
              baseConfig.base,
              environment.production
                ? baseConfig.production
                : baseConfig.development,
              specialConfig.skin
            );
            if (!this.mySkinConfig.externalUrls) {
              this.mySkinConfig.externalUrls = {};
            }

            this.mySkinConfig.externalUrls.productDocumentation =
              '/product-documentation/cloud-computing';

            // Overwrite jumpin-url on staging and in VTE
            if (
              location.href.indexOf('gridscale.dev') !== -1 &&
              this.mySkinConfig.login.jumpInUrl
            ) {
              this.mySkinConfig.login.jumpInUrl =
                this.mySkinConfig.login.jumpInUrl.replace(
                  'gridscale.io',
                  'gridscale.dev'
                );
            } else if (location.hostname.indexOf('.nip.io') !== -1) {
              const splittedUrl = location.hostname.split('.');
              splittedUrl.shift();
              const mainDomain = splittedUrl.join('.');
              this.mySkinConfig.login.jumpInUrl = 'http://partner.' + mainDomain;
            }

            this.addScriptTagsToHead(this.mySkinConfig);

            _resolve();
          },
          (error) => {
            _reject(error);
          }
        );

    });
  }

  private addScriptTagsToHead(_config: MergedSkinConfig) {
    if ('scripts' in _config) {
      _config.scripts?.forEach((js) => {
        // Disable google tag manager for localhost
        if (
          window.location.hostname === 'localhost' &&
          (js.content.indexOf('gtm.js') !== -1 ||
            js.content.indexOf('google') !== -1)
        ) {
          console.log('No Tracking on Localhost');
          return;
        }

        const myJS: HTMLScriptElement = this.doc.createElement('script');

        myJS.setAttribute('type', js.type);
        myJS.text = js.content;

        this.doc.head.appendChild(myJS);
      });
    }
  }


}
